@import url("https://use.typekit.net/bza3vcv.css");

* {
    font-family: "proxima-nova";
}
@import url("https://fonts.googleapis.com/css?family=Baloo+Paaji|Open+Sans:300,300i,400,400i,600,600i,700,700i");

.my-font {
  font-family: 'Baloo Paaji', cursive;
}

@import url('https://fonts.googleapis.com/css2?family=Stylish&display=swap');

.custom-font {
  font-family: 'Stylish', sans-serif;

}
  
.progress-bar {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: gray;
  transform-origin: 0%;
}

.dark .dark\:progress-bar {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: #FFE332;
  transform-origin: 0%;
}

#menu-open:checked ~ #sidebar {
  --tw-translate-x: 0;
}

#menu-open:checked ~ * #menu-open-icon {
  display: none;
}
#menu-open:checked ~ * #menu-close-icon {
  display: block;
}
